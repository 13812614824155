import dayjs from 'dayjs';
import * as z from 'zod';

const parseDateTime = (dateTime: string): { date: string; time: string } => {
  const delimiters = ['T', ' ', '+'];
  const result = [];
  let currentChunk = '';

  for (const char of dateTime) {
    if (delimiters.includes(char)) {
      if (currentChunk) {
        result.push(currentChunk);
        currentChunk = '';
      }
    } else {
      currentChunk += char;
    }
  }

  if (currentChunk) {
    result.push(currentChunk);
  }

  return {
    date: result[0] || dateTime,
    time: result[1] || '00:00:00'
  };
};

export const dateTimeSchema = z
  .string()
  .refine((dateTime: string) => {
    const { date, time } = parseDateTime(dateTime);
    return dayjs(`${date}T${time}+01:00`).isValid();
  })
  .transform((dateTime: string) => {
    const { date, time } = parseDateTime(dateTime);
    return dayjs(`${date}T${time}+01:00`);
  });

export const dateSchema = z
  .string()
  .refine((dateTime: string) => {
    const { date } = parseDateTime(dateTime);
    return dayjs(date).isValid();
  })
  .transform((dateTime: string) => {
    const { date } = parseDateTime(dateTime);
    return dayjs(date);
  });

export type DateTime = z.output<typeof dateTimeSchema | typeof dateSchema>;
